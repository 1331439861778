import React, { useEffect, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { OAuthCredential, onAuthStateChanged } from '@firebase/auth';
import axios from 'axios';
import { configureChains, mainnet, useAccount, useConnect, useDisconnect, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

import { firebaseAuth, signInWithTwitter } from './firebase';

const { chains } = configureChains(
  [mainnet],
  [publicProvider()],
);

const walletConnectProjectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID as string;

// Set up wagmi config
const wagmiConfig = defaultWagmiConfig({
  projectId: walletConnectProjectId,
  chains,
})

createWeb3Modal({ wagmiConfig, projectId: walletConnectProjectId, chains })

const tweetText = 'I’ve been recruited by the Entangle Elite to fight in the Oracle Wars and solve Kai’s Ledger. \n' +
  '\n' +
  '\n' +
  '@WebverseNFT \n' +
  '#enteranalternatereality';

const hasUserEntered = async (
  authToken: string,
  walletAddress?: string,
  credentials?: OAuthCredential
) => {
  try {
    const { data } = await axios.post(`${process.env.REACT_APP_API_HOST}/status`, {
      credentials,
      walletAddress,
    }, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return data.success;
  } catch (e) {
    //
  }
  return false;
}

const TweetCheck = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isChecking, setIsChecking] = useState(false);
  const [credentials, setCredentials] = useState<OAuthCredential | null>(null);
  const [enteredCompetition, setEnteredCompetition] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { address: walletAddress } = useAccount();
  const { disconnect } = useDisconnect();
  const { pendingConnector } = useConnect();
  const isConnecting = !!pendingConnector;
  const { open } = useWeb3Modal();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      let entered = false;

      if (user && firebaseAuth.currentUser) {
        try {
          const authToken = await firebaseAuth.currentUser.getIdToken();
          entered = await hasUserEntered(authToken);
        } catch (e) {
          //
        }
      }

      if (entered) {
        window.localStorage.setItem('tweeted', '1');
        setIsChecking(false);
      }

      setEnteredCompetition(entered);
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    }
  }, []);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let attempts = 0;

    const checkIfUserEntered = async () => {
      if (!credentials
        || !firebaseAuth.currentUser
        || !walletAddress) {
        return;
      }

      let authToken: string | null = null;
      try {
        authToken = await firebaseAuth.currentUser.getIdToken();
      } catch (e) {
        //
      }

      if (!authToken) {
        return;
      }

      const hasTweeted = await hasUserEntered(authToken, walletAddress, credentials);
      if (window.localStorage.getItem('tweeted') && hasTweeted) {
        setEnteredCompetition(true);
        setIsChecking(false);
        return;
      }

      if (attempts === 10) {
        setCredentials(null);
        setIsChecking(false);
        setErrorMessage('Failed to check if user tweeted!');
        return;
      }

      attempts++;
      timeout = setTimeout(checkIfUserEntered, 3000);
    }

    checkIfUserEntered();

    return () => {
      if (!timeout) return;
      clearTimeout(timeout);
    }
  }, [credentials, walletAddress]);

  const onClickConnectWithTwitter = async () => {
    if (isLoading) return;
    window.localStorage.removeItem('tweeted');

    setErrorMessage('');
    setIsLoading(true);
    setCredentials(null);

    const result = await signInWithTwitter();
    if (result?.errorMessage || !result?.credential) {
      setErrorMessage(result?.errorMessage ?? 'Failed to sign in with Twitter!');
      setIsLoading(false);
      return;
    }

    setCredentials(result.credential);
    setIsLoading(false);
  }

  const onClickConnectWallet = async () => {
    if (isConnecting) return;
    setErrorMessage('');
    try {
      open();
    } catch (e) {
      setErrorMessage('Failed to connect wallet');
    }
  }

  const onLogoutClick = async () => {
    setCredentials(null);
    setEnteredCompetition(false);
    window.localStorage.removeItem('tweeted');

    try {
      disconnect();
    } catch (e) {
      //
    }

    await firebaseAuth.signOut()
  }

  const onTweetClick = async () => {
    if (isChecking) return;
    setIsChecking(true);
    window.open('https://twitter.com/intent/tweet?text=' + encodeURIComponent(tweetText), '_blank');
    window.localStorage.setItem('tweeted', '1');
  }

  const onGoBackClick = async () => {
    window.open('https://kaisledger.com');
  }

  if (isConnecting) {
    return (
      <p>Connecting...</p>
    )
  }

  if (isChecking) {
    return (
      <p>Checking...</p>
    )
  }

  if (enteredCompetition && window.localStorage.getItem('tweeted')) {
    return (
      <>
        <SuccessMessage>Your recruitment entry is stored.</SuccessMessage>
        <LogoutButton onClick={onLogoutClick}>Logout</LogoutButton>
        <LogoutButton onClick={onGoBackClick}>Go back to kaisledger.com</LogoutButton>
      </>
    )
  }

  return (
    <>
      {!credentials && <StyledButton onClick={onClickConnectWithTwitter}>Connect Twitter</StyledButton>}
      {/*@ts-ignore*/}
      {!!credentials && <AccountText>Twitter as {firebaseAuth.currentUser?.reloadUserInfo?.screenName ?? firebaseAuth.currentUser?.displayName}</AccountText>}
      {!walletAddress && <StyledButton onClick={onClickConnectWallet}>Connect Wallet</StyledButton>}
      {!!walletAddress && <AccountText>Wallet as {walletAddress.slice(0,5)}...{walletAddress.slice(-5)}</AccountText>}
      {!!credentials && !!walletAddress && (
        <>
          <br />
          <StyledButton onClick={onTweetClick}>Tweet</StyledButton>
        </>
      )}
      {(!!credentials || !!walletAddress) && (
        <LogoutButton onClick={onLogoutClick}>Logout</LogoutButton>
      )}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <LogoutButton onClick={onGoBackClick}>Go back to kaisledger.com</LogoutButton>
    </>
  );
}


const App = () => {
  return (
    <WagmiConfig config={wagmiConfig}>
      <GlobalStyle />
      <Wrapper>
        <Title>Kai's Ledger is solved.</Title>
        {false && <TweetCheck />}
      </Wrapper>
    </WagmiConfig>
  );
}

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Courier Prime', monospace;
    background-color: #000;
    color: #fff;
  }
  
  * {
    padding: 0;
    margin: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const StyledButton = styled.div`
  margin-bottom: 20px;
  padding: 10px 20px;
  border: 1px solid #fff;
  cursor: pointer;
    
  &:hover {
    opacity: 0.8;
  }
    
  &:active {
    opacity: 0.5;
  }
`;

const ErrorMessage = styled.p`
  margin-top: 20px;
  color: #f00;
  font-size: 14px;
`;

const AccountText = styled.p`
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 12px;
`;

const SuccessMessage = styled.p`
  margin-top: 20px;
  color: #1aff74;
  font-size: 14px;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  text-align: center;
`;

const LogoutButton = styled.p`
  margin-top: 40px;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export default App;
