import { initializeApp } from 'firebase/app';
import {
  getAuth,
  User,
  TwitterAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { OAuthCredential } from '@firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBV_nkdwnSKt8erZGZcqqRdsHUik_Sglhg",
  authDomain: "webverse-e9c13.firebaseapp.com",
  projectId: "webverse-e9c13",
  storageBucket: "webverse-e9c13.appspot.com",
  messagingSenderId: "618114393202",
  appId: "1:618114393202:web:06f75c2c1441742c948eeb"
};

const firebase = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebase);

const firebaseErrorCodeToMessage = {
  'auth/invalid-email': 'Invalid email',
  'auth/user-disabled': 'User disabled',
  'auth/user-not-found': 'User not found',
  'auth/wrong-password': 'Wrong password',
}

const provider = new TwitterAuthProvider();

export const signInWithTwitter = async (): Promise<{
  success: boolean;
  errorMessage?: string;
  user?: User;
  credential?: OAuthCredential;
}> => {
  let errorMessage;

  try {
    const userCredential = await signInWithPopup(firebaseAuth, provider);
    const credential = TwitterAuthProvider.credentialFromResult(userCredential);
    if (userCredential?.user && credential) {
      return { success: true, user: userCredential.user, credential };
    }
  } catch (e) {
    // @ts-ignore
    errorMessage = firebaseErrorCodeToMessage[e.code];
  }

  return { success: false, errorMessage: errorMessage ?? 'Failed to login, please try again' }
}

export default firebase;
